import { VStack } from '@chakra-ui/react'
import { useActiveMenuItem } from './hooks/useActiveMenuItem'
import { useFormat } from '../../../helpers_composable/hooks/useFormat'
import { SidebarAccordion } from '../sidebar/sidebar-accordion'
import { SidebarNav } from '../sidebar/sidebar-nav'

export interface AccountDashboardNavProps {
  state?: 'Expanded' | 'Collapsed'
  logout: () => void
}

export const DEFAULT_ACCOUNT_DASHBOARD_PATH = '/account/dashboard'

export const AccountDashboardNav = ({ state = 'Collapsed', logout }: AccountDashboardNavProps) => {
  const { formatMessage } = useFormat({ name: 'common' })

  const activeItem = useActiveMenuItem()

  return (
    <>
      {/* Desktop */}
      <VStack spacing={'0'} width={'full'} alignItems={'stretch'} display={{ base: 'none', md: 'flex' }}>
        <SidebarNav size="Large" logout={logout} activeItem={activeItem} />
      </VStack>

      {/* Mobile */}
      <VStack spacing={'0'} width={'full'} alignItems={'stretch'} display={{ base: 'flex', md: 'none' }}>
        <SidebarAccordion
          expanded={state === 'Expanded'}
          icon={activeItem?.icon}
          label={formatMessage({ id: activeItem?.intlId ?? 'order' })}
        >
          <SidebarNav size="Small" logout={logout} activeItem={activeItem} />
        </SidebarAccordion>
      </VStack>
    </>
  )
}
