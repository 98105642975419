import { Box, Text, VStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'

export const ContactUs = ({ children }: { children?: React.ReactNode }) => {
  const { formatMessage } = useFormat({ name: 'common' })

  return (
    <Box paddingTop={{ base: '0', md: '24px' }}>
      <VStack
        align={'flex-start'}
        border={{ base: 'none', md: '1px solid #E2E2E2' }}
        padding={{ base: '12px 0', md: '24px' }}
        spacing={'0'}
        gap={'0'}
        alignItems={'stretch'}
      >
        <Box padding={'16px 0'}>
          <Text
            textStyle={{ base: 'eyebrow-50', md: 'heading-desktop-200' }}
            color={{ base: 'text-muted', md: 'text' }}
          >
            {formatMessage({ id: 'account.support.needHelp' })}
          </Text>
          <Text textStyle={'callouts-blockquote-100'} color={'text-muted'} display={{ base: 'none', md: 'flex' }}>
            {formatMessage({ id: 'account.support.contactUs' })}
          </Text>
        </Box>

        <VStack align="flex-start" spacing={'0'} gap={'4px'}>
          {children}
        </VStack>
      </VStack>
    </Box>
  )
}
