import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { CONTACT_US_ITEMS, ContactUsItem } from 'composable/components/sidebar/sidebar-nav-contact-us'
import { menuItems } from 'composable/components/sidebar/sidebar-nav-menu'

const getActiveItemData = (pathName: string) =>
  CONTACT_US_ITEMS.find((item: ContactUsItem) => item.path === pathName) ??
  menuItems.find((item) => pathName.includes(item.path))

export const useActiveMenuItem = () => {
  const [activeItem, setActiveItem] = useState(null)
  const router = useRouter()

  useEffect(() => {
    const active = getActiveItemData(router.asPath.split('#')[1] || 'profile')
    setActiveItem(active)
  }, [router.asPath])

  return activeItem
}
