import { useRouter } from 'next/router'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoHelpBuoyOutline, IoRefreshOutline } from 'react-icons/io5'
import { ContactUs } from './contact-us'
import { SidebarItem } from './sidebar-item'
import { useComposable } from '../composable-provider'

interface SidebarNavContactUsProps {
  activeItem?: ContactUsItem
}

export type ContactUsItem = {
  path: string
  icon: JSX.Element
  label: string
  intlId: string
}

export const CONTACT_US_ITEMS = [
  {
    path: 'static/customer-service',
    label: 'Customer Support',
    intlId: 'account.dashboard.menu.customerSupport',
    icon: <IoHelpBuoyOutline size={'24px'} />,
  },
  {
    path: 'static/customer-service#shipping-and-information',
    label: 'Shipping & Return Policy',
    intlId: 'account.dashboard.menu.shippingAndReturn',
    icon: <IoRefreshOutline size={'24px'} />,
  },
] as ContactUsItem[]

export const SidebarNavContactUs = ({ activeItem }: SidebarNavContactUsProps) => {
  const { accountDrawer } = useComposable()
  const { formatMessage } = useFormat({ name: 'common' })

  return (
    <ContactUs>
      {CONTACT_US_ITEMS.map((item: ContactUsItem) => (
        <SidebarItem
          key={item.path}
          state={activeItem?.path === item.path ? 'Active' : 'Default'}
          href={item.path ?? ''}
          label={formatMessage({ id: item.intlId })}
          rootProps={{
            borderBottom: 'none',
            leftIcon: item.icon,
            padding: '0',
            onClick: () => accountDrawer.onClose(),
          }}
          textProps={{
            textStyle: {
              base: 'callouts-blockquote-75',
              md: 'callouts-blockquote-100',
            },
          }}
        />
      ))}
    </ContactUs>
  )
}
